<template>
  <div>
    <div class="container-fluid pb-3 pt-5 pt-md-6" v-if="!formVisible">
      <div class="row col-12 page-head">
        <nav aria-label="breadcrumb" role="navigation">
          <ol class="breadcrumb page-head-nav">
            <li class="breadcrumb-item">
              <router-link :to="{ name: 'dashboard' }">Home</router-link>
            </li>
            <li v-if="user.role == 'ared_admin'" class="breadcrumb-item">
              <router-link :to="{ name: 'users' }">System Admin</router-link>
            </li>
            <li v-if="user.role == 'Manufacturer'" class="breadcrumb-item">
              <router-link :to="{ name: 'manufacturers-list' }"
                >Manufacturers</router-link
              >
            </li>
            <li class="breadcrumb-item">User Details</li>
          </ol>
        </nav>
      </div>
      <div class="row">
        <div class="user-profile col-md-4 mb-3">
          <div class="user-display">
            <div class="user-display-bg">
              <img src="/img/deal.png" alt="Profile Background" />
            </div>
            <div class="user-display-bottom">
              <div class="user-display-avatar">
                <img src="/img/avatar.png" alt="Mshiriki" />
              </div>
              <div class="user-display-info">
                <div class="name">
                  {{ user.first_name }} {{ user.last_name }}
                </div>
                <div class="nick">
                  <span class="mdi mdi-phone"></span> {{ user.phone }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8 mb-3">
          <div class="card shadow-sm">
            <div class="card-body">
              <div class="scroll">
                <h3 class="text-uppercase mb-3">Full profile</h3>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>{{ user.first_name }} {{ user.last_name }}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{{ user.email }}</td>
                    </tr>
                    <tr v-if="user.partner">
                      <th>Partner</th>
                      <td>
                        <router-link
                          :to="{
                            name: 'partner-details',
                            params: { id: user.partner.id },
                          }"
                          >{{ user.partner_data.name }}</router-link
                        >
                      </td>
                    </tr>
                    <tr>
                      <th>Phone Number</th>
                      <td>{{ user.phone }}</td>
                    </tr>
                    <tr>
                      <th>Gender</th>
                      <td>{{ user.gender }}</td>
                    </tr>
                    <tr>
                      <th>Country</th>
                      <td>{{ user.country }}</td>
                    </tr>
                    <tr>
                      <th>Role</th>
                      <td>{{ user.role | roleFormat }}</td>
                    </tr>
                    <tr>
                      <th>Type</th>
                      <td>{{ user.user_type }}</td>
                    </tr>
                    <!-- <tr>
                      <th>Status</th>
                      <td>
                        <span v-if="user.status == 'active'">Active</span>
                        <span v-else class="text-danger">Suspended</span>
                      </td>
                    </tr> -->

                    <tr>
                      <th>Created</th>
                      <td>{{ new Date(user.date_joined).toLocaleString() }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row" v-if="loggedInUser.role === adminRole">
                <div class="col-4 mx-auto">
                  <b-button
                    v-b-modal.delete-modal
                    variant="danger"
                    class="float-left"
                    >Delete User</b-button
                  >
                  <b-button
                    @click="toggleFormVisible"
                    variant="success"
                    class="float-right"
                    >Update User</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UpdateForm
      v-else
      :user="user"
      @hide-form="toggleFormVisible"
      @get-user="getUser"
    />
    <DeleteEntity :entityType="user.first_name" />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import UpdateForm from "@/components/Users/UpdateForm";
import DeleteEntity from "@/components/DeleteEntity";

export default {
  components: {
    DeleteEntity,
    UpdateForm,
  },
  data() {
    return {
      user: {},
      formVisible: false,
      adminRole: "ared_admin",
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  filters: {
    roleFormat: function (value) {
      if (value == "ared_admin") {
        return "Admin";
      } else if (value == "ared_staff") {
        return "Staff";
      } else if (value == "Manufacturer") {
        return "Manufacturer";
      }
    },
  },
  mounted() {
    this.getUser(this.$route.params.id);
  },
  methods: {
    toggleFormVisible() {
      this.formVisible = !this.formVisible;
    },
    getUser(id) {
      axios
        .get(`v1/auth/users/${id}/details/`)
        .then((response) => {
          if (response.status === 200) {
            this.user = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    },
  },
};
</script>

<style></style>
