<template>
  <div class="card">
    <div class="card-body">
      <h3 class="mb-4">Update User Details</h3>
      <form @submit.prevent="handleSubmit">
        <div class="">
          <div class="input-group mb-4">
            <input
              type="text"
              class="form-control"
              v-model="user.first_name"
              placeholder="Enter First Name"
              required
            />
            <small
              v-if="responseErrors.first_name"
              class="text-danger help-text"
            >
              {{ responseErrors.first_name[0] }}
            </small>
          </div>
          <div class="input-group mb-4">
            <input
              type="text"
              class="form-control"
              v-model="user.last_name"
              placeholder="Enter Last Name"
              required
            />
            <small
              v-if="responseErrors.last_name"
              class="text-danger help-text"
            >
              {{ responseErrors.last_name[0] }}
            </small>
          </div>
          <div class="mb-4">
            <div class="input-group">
              <input
                type="email"
                class="form-control"
                v-model="user.email"
                placeholder="Enter Email"
                required
              />
            </div>
            <small v-if="responseErrors.email" class="text-danger help-text">
              {{ responseErrors.email[0] }}
            </small>
          </div>
          <div class="input-group mb-4">
            <vue-tel-input
              v-model="user.phone"
              placeholder="Enter Phone Number"
              class="w-100"
              v-bind="bindProps"
              required
            ></vue-tel-input>
            <small v-if="responseErrors.phone" class="text-danger help-text">
              {{ responseErrors.phone[0] }}
            </small>
          </div>
          <div class="input-group mb-4">
            <select
              type="text"
              class="form-control"
              v-model="user.gender"
              required
            >
              <option value="">Select Gender</option>
              <option value="Female">Female</option>
              <option value="Male">Male</option>
              <option value="Other">Other</option>
            </select>
            <small v-if="responseErrors.gender" class="text-danger help-text">
              {{ responseErrors.gender[0] }}
            </small>
          </div>
          <!-- <div class="input-group mb-4">
            <input
              type="text"
              class="form-control"
              v-model="national_id_no"
              placeholder="National ID"
              required
            />
            <small v-if="responseErrors.national_id_no" class="text-danger help-text">
              {{ responseErrors.national_id_no[0] }}
            </small>
          </div> -->
          <div class="input-group mb-4">
            <select
              v-model="user.country"
              class="form-control"
              id="country"
              name="country"
              required
            >
              <option value="">Select Country</option>
              <option
                v-for="country in countries"
                :value="country"
                :key="country"
              >
                {{ country }}
              </option>
            </select>
            <small v-if="responseErrors.country" class="text-danger help-text">
              {{ responseErrors.country[0] }}
            </small>
          </div>
          <div class="input-group mb-4">
            <select
              v-model="userType"
              class="form-control"
              id="role"
              name="type"
              required
            >
              <option value="">Select User Type</option>
              <option
                v-for="userType in userTypes"
                :value="userType.value"
                :key="userType"
              >
                {{ userType.name }}
              </option>
            </select>
            <small v-if="responseErrors.type" class="text-danger help-text">
              {{ responseErrors.type[0] }}
            </small>
          </div>
        </div>
        <div class="text-center py-3">
          <button
            class="btn btn-outline-info mr-2 w-25"
            @click="$emit('hide-form')"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary w-25"
            :disabled="loading"
            type="submit"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            {{ submit }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { countries } from "../../data/countries";

export default {
  name: "UpdateUserForm",
  data() {
    return {
      loading: false,
      responseErrors: {},
      submit: "Update User",
      bindProps: {
        mode: "international",
      },
      userTypes: [
        {
          name: "Admin",
          value: "Admin",
        },
        {
          name: "Manufacturer",
          value: "Manufacturer",
        },
        {
          name: "Customer Support Agent",
          value: "Customer Support Agent",
        },
        {
          name: "Sales Representative",
          value: "Sales Representative",
        },
      ],
      countries: countries,
    };
  },
  computed: {
    userType: {
      get() {
        const type = this.getUserTypeFromRole(this.user);
        return type;
      },
      set(value) {
        this.user.user_type = value;
      },
    },
  },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    getUserRole(userType) {
      switch (userType) {
        case "Admin":
          return "ared_admin";
        case "Manufacturer":
          return "Manufacturer";
        default:
          return "ared_staff";
      }
    },
    getValidUserType(userType) {
      switch (userType) {
        case "Admin":
          return "Regular User";
        case "Manufacturer":
          return "Regular User";
        default:
          return userType;
      }
    },
    getUserTypeFromRole(user) {
      if (user.role === "ared_admin") {
        return "Admin";
      }

      if (user.role === "Manufacturer") {
        return user.role;
      }

      return user.user_type;
    },
    async handleSubmit() {
      this.loading = true;
      this.submit = "Updating User";

      this.user.role = this.getUserRole(this.user.user_type);
      this.user.user_type = this.getValidUserType(this.user.user_type);

      await axios({
        url: `v1/auth/users/${this.user.id}/`,
        method: "PATCH",
        data: this.user,
      })
        .then((response) => {
          this.loading = false;
          if (response.status === 200) {
            this.$emit("get-user", this.$route.params.id);
            this.$swal("Success", "User updated Successfully", "success");
            this.$emit("hide-form");
          }
        })
        .catch((error) => {
          this.responseErrors = error.response.data;
          this.loading = false;
          this.submit = "Update User";
        });
    },
  },
};
</script>
