<template>
  <div class="">
    <b-modal
      id="delete-modal"
      size="sm"
      centered
      hide-backdrop
      @ok="handleOk"
      >{{ modalMessage }}</b-modal
    >
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
export default {
  name: "DeleteEntity",
  props: {
    entityType: {
      type: String,
      default() {
        return "partner";
      },
    },
    action: {
      type: String,
      default() {
        return "suspend";
      },
    },
    modalMessage: {
      type: String,
      default() {
        return `confirm that you want to activate ${this.entityType}!`;
      },
    },
  },
  // data() {
  //   return {
  //     modalMessage: `confirm that you want to activate ${this.entityType}!`,
  //   };
  // },
  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.entityType === "partner" && this.action === "suspend") {
        this.disablePartner();
      } else if (this.entityType === "partner" && this.action === "activate") {
        this.activatePartner();
      } else {
        this.disableUser();
      }
    },
    async disableUser() {
      const data = { is_active: false, status: "terminated" };
      try {
        await axios
          .patch(`v1/auth/users/${this.$route.params.id}/`, data)
          .then((response) => {
            if (response.status === 200) {
              this.$swal("Success", "User suspended successfully", "success");
              router.push({ name: "users" });
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
    async disablePartner() {
      const data = { is_active: false };
      try {
        await axios
          .patch(`v1/partners/${this.$route.params.id}/`, data)
          .then((response) => {
            if (response.status === 200) {
              this.$swal(
                "Success",
                "partner suspended successfully",
                "success"
              );
              this.$bvModal.hide("delete-modal");
              router.push({ name: "partners" });
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
    async activatePartner() {
      const data = { is_active: true };
      try {
        await axios
          .patch(`v1/partners/${this.$route.params.id}/`, data)
          .then((response) => {
            if (response.status === 200) {
              this.$swal("Success", "partner activated", "success");
              this.$bvModal.hide("delete-modal");
              router.push({ name: "partners" });
            }
          });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
